@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Bodoni 72 Smallcaps';
	src: url('../../dist/font/BodoniSvtyTwoSCITCTT-Book.woff2') format('woff2'),
	url('../../dist/font/BodoniSvtyTwoSCITCTT-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: 'Bodoni 72 Smallcaps';
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.hide {
	display: none !important;
}

.has-bg {
	position: relative;

	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.content {
		position: relative;
	}
}

.btn {
	display: inline-block;
	text-align: center;
	padding: 10px 30px;
	text-transform: capitalize;
	border: solid 1px transparent;
	background-color: transparent;
	font-size: 18px;

	&.btn-white {
		background-color: $white;
		border-color: $white;
		color: $black;
		@include hover-focus {
			color: $yellow;
		}
	}

	&.btn-yellow {
		background-color: $yellow;
		border-color: $yellow;
		color: $white;
		@include hover-focus {
			color: $black;
		}
	}

	&.btn-purple {
		background-color: $purple;
		border-color: $purple;
		color: $white;
		@include hover-focus {
			color: $yellow;
		}
	}

	&.btn-rounded {
		border-radius: 50px
	}
}

.split {
	@include flex(column, initial, initial);

	.side {
		flex: 1;
	}

	@include tablet-up {
		@include flex(row, initial, initial);
	}
}

.social {
	@include flex(row, center, center);
	gap: 20px;

	a {
		display: block;

		img {
			width: 30px;
			height: 30px;
		}
	}
}

header {
	img {
		width: 100%;
	}
}

main {

	section {

		&#tour {
			background-color: $purple;


			.bg {
				opacity: .1;
			}

			.content {
				padding: 60px 0 0 0;

				@include tablet-up {
					.content {
						padding: 120px 0 0 0;
					}
				}
			}

			.tour-dates {
				background: $white;
				padding: rem(30) rem(60);
				color: $purple;
				margin-bottom: 60px;
				font-size: rem(18);

				@include tablet-up {
					margin-bottom: 120px;
				}

				.tour-date {
					display: flex;
					flex-wrap: nowrap;
					flex-direction: row;
					align-items: center;

					@include tablet-down {
						display: block;
						text-align: center;

						> div + div {
							margin-top: rem(5);
						}

						> div:last-child {
							text-align: center !important;
						}
					}

					+ .tour-date {
						margin-top: rem(20);
					}

					> div {
						&:nth-child(1) {
							flex: 0 0 25%;
						}

						&:nth-child(2) {
							flex: 0 0 25%;
						}

						&:nth-child(3) {
							flex: 0 0 30%;
						}

						&:nth-child(4) {
							flex: 0 0 20%;
							text-align: right;

							a {
								@extend .btn;
								@extend .btn-yellow;
								@extend .btn-rounded;

								color: #fff !important;
								opacity: 1;

								@include hover-focus {
									opacity: 0.8;
									color: #fff !important;
								}
							}
						}
					}
				}
			}
		}

		&#top {
			background-color: $purple;

			.bg {
				opacity: .1;
			}

			.content {
				padding: 60px 0;

				.container {
					> .split {
						> .side {
							&:first-child {
								img {
									width: 100%;
								}
							}

							&:last-child {
								.content {
									height: 100%;
									padding: 20px;
									@include flex(column, center, center);
									text-align: center;

									img {
										margin-bottom: -80px;
									}

									p {
										color: $yellow;

										&.top {
											font-size: 32px;
										}

										&.mid {
											font-size: 38px;
										}

										&.bottom {
											font-size: 32px;
											margin-bottom: 20px;
										}
									}

									.buttons {
										width: 100%;
										justify-content: center;
										gap: 20px;

										.side {
											max-width: 200px;
											margin: 0 auto;

											.btn {
												display: block;
												width: 100%;
												max-width: 200px;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			@include tablet-up {
				.content {
					padding: 120px 0;

					.container {
						> .split {
							> .side {
								&:last-child {
									.content {
										.buttons {
											.side {
												margin: 0;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&#bottom {
			z-index: 2;

			.content {
				padding: 60px 0;

				.container {
					text-align: center;

					.bio {
						.extra {
							display: none;
						}
					}

					p {
						color: $black;
						font-size: 18px;
						line-height: 1.6;
						max-width: 700px;
						margin: 0 auto 40px;
					}

					.btn {
						display: block;
						margin: 0 auto;
						max-width: 200px;
					}

					img {
						position: absolute;
						bottom: -40px;
						left: calc(50% - 40px);
						width: 80px;
						height: 80px;
					}
				}
			}

			@include tablet-up {
				.content {
					padding: 120px 0 160px;

					.container {
						img {
							width: 200px;
							height: 200px;
							bottom: -100px;
							left: calc(50% - 100px);
						}

						p {
							font-size: 24px;
						}
					}
				}
			}
		}

	}

}

footer {
	background-color: $purple;

	.bg {
		opacity: .4;
	}

	> .content {
		padding: 60px 0 30px;

		.container {
			.video {
				display: block;
				margin-bottom: 60px;

				img {
					width: 100%;
					aspect-ratio: 6/4;
					margin: 0 auto 20px;
					object-fit: cover;
					max-width: 800px;
				}

				.title {
					color: $yellow;
					text-align: center;
					font-size: 24px;
					max-width: 800px;
					margin: 0 auto 10px;
					// text-transform: uppercase;
				}
			}

			> .btn {
				text-transform: none;
			}

			text-align: center;

			.social {
				margin: 30px 0;
			}

			.copyright {
				p {
					font-size: 10px;
					margin-bottom: 0;
					line-height: 1.4;
				}
			}
		}
	}

	@include tablet-up {
		> .content {
			/*padding: 180px 0 60px;*/

			.container {
				.video {
					.title {
						font-size: 38px;
					}
				}
			}
		}
	}
}

.events {
	padding-bottom: 40px;

	h2 {
		color: $yellow;
		font-size: 45px;
		text-align: center;
		font-weight: 400;
		margin-bottom: 20px;
	}

	p {
		font-size: 16px;
		max-width: 700px;
		margin: 0 auto 40px;

		br {
			display: none;
		}
	}

	.wrapper {
		background-color: #fcf9f4;
		padding: 20px;
		max-width: 800px;
		margin: 0 auto;

		.event {
			color: $purple;
			@include flex(column, center, center);
			gap: 10px;
			font: 16px;
		}
	}

	@include tablet-up {
		padding-bottom: 100px;
		p {
			font-size: 24px;

			br {
				display: block;
			}
		}
		.wrapper {
			padding: 40px;

			.event {
				@include flex(row, space-between, center);
				font-size: 24px;
				padding: 15px 0;
			}
		}
	}
}